import {
  ComponentRef,
  ComponentClientSpecMapEntry,
} from '@wix/editor-platform-sdk-types';

import { getGroup } from 'api/groups';
import { FEED_WIDGET_SECRET_SLUG } from 'api/groups/constants';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';
import { EditorPlatformMigration } from '../EditorPlatformMigration';

import { EWidgets } from 'Groups/panels/AddonsPanel/constants';

/**
 * Sets last created public group as group for feed widget
 */
export default class extends EditorPlatformMigration {
  name = 'feed-widget-default-group';

  async install(component: ComponentClientSpecMapEntry, compRef: ComponentRef) {
    if (component.widgetId !== EWidgets.FEED) {
      return false;
    }

    const response = await this.flowAPI.httpClient.request(
      getGroup(FEED_WIDGET_SECRET_SLUG),
    );

    const group = response.data.group;

    if (!group?.id) {
      return false;
    }

    await this.sdk.document.tpa.data.set(GROUPS_APP_DEFINITION_ID, {
      compRef,
      key: 'groupId',
      scope: 'COMPONENT',
      value: group.id,
    });

    return true;
  }
}
