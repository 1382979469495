import {
  ComponentClientSpecMapEntry,
  ComponentRef,
} from '@wix/editor-platform-sdk-types';

import { LayoutType } from 'settings/consts';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';
import { EditorPlatformMigration } from '../EditorPlatformMigration';

const GROUPS_WIDGET_ID = 'a7dcdfcb-8abd-4008-af19-fed5fcd12b40';

/**
 * Sets sidebar layout in Group Lis Page for new users
 */
export default class extends EditorPlatformMigration {
  name = 'group-list-layout';

  async install(component: ComponentClientSpecMapEntry, compRef: ComponentRef) {
    if (component.widgetId === GROUPS_WIDGET_ID) {
      await this.sdk.document.tpa.data.set(GROUPS_APP_DEFINITION_ID, {
        compRef,
        scope: 'COMPONENT',
        key: 'groupListLayout',
        value: LayoutType.sideBar,
      });
    }

    return true;
  }
}
