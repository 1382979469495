export enum EManagementAction {
  MainAction = 'mainAction',
  CreateGroup = 'createGroup',
  Addons = 'addons',
  Setup = 'setup',
}

export enum EPlatformAction {
  Uninstall = 'uninstall',
  OpenDashboard = 'openDashboard',
}

export const GROUPS_SUPPORT_ARTICLE_ID = '57cfed92-b0db-435e-8872-03b6637bd2e6';
